
import { defineComponent, computed } from "vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import store from "@/store";

export default defineComponent({
  name: "policy-information-credit-note",
  components: { GoBackButton },
  props: ["publicId"],
  data() {
    return { creditNote: {}, role: variables.INDIVIDUAL_CLIENT_USER_ROLE };
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    return { user };
  },
  created() {
    this.getCreditNoteInformation();

    //Set page title
    document.title = "Credit Note Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getCreditNoteInformation() {
      //Fetch policy credit note information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.FINANCE_CREDIT_NOTE_DETAILS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.creditNote = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    printPage(el) {
      variables.printPage(el);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
    truncate(value) {
      return variables.truncate(value, 25);
    },
  },
});
